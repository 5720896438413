import React from "react";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Home from "./pages/home";
import Help from "./pages/help";
import "bootswatch/dist/spacelab/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/help" exact>
            <Help />
          </Route>
        </Switch>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
