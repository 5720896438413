import * as React from "react";
export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <footer>
          Copyright(c) 2021 by Naoko Hirai, all rights reserved.
        </footer>
      </div>
    );
  }
}
