import * as React from "react";
import "./styles/selectFile.css";
interface IProps {
  name: React.RefObject<HTMLInputElement>;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  selected: boolean;
}
interface IState {}
export default class SelectFile extends React.Component<IProps, IState> {
  private fileFiled: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  selectFile() {
    if (this.fileFiled.current) {
      this.fileFiled.current.click();
    }
  }
  render() {
    if (!this.props.selected) {
      return (
        <React.Fragment>
          <div className="select_file form-group">
            <label>ファイルから投稿</label>
            <input
              className="form-control contets_area"
              type="text"
              ref={this.props.name}
              placeholder="ファイルを選択してください"
              readOnly
            />
            <button
              className="btn btn-warning"
              onClick={() => this.selectFile()}
            >
              選択
            </button>
            <input
              className="hidden"
              type="file"
              ref={this.fileFiled}
              onChange={this.props.onChange}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}
