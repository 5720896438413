import * as React from "react";
import SelectFile from "../components/selectFile";
import PostContents from "../components/postContents";
interface IProps {}
interface IState {
  contents: string;
  converted: boolean;
  selected: boolean;
}
const NEW_LINE: string = process.env.REACT_APP_NEW_LINE
  ? process.env.REACT_APP_NEW_LINE
  : "\n";
const FB_NEW_LINE_CODE: number = process.env.REACT_APP_FB_NEW_LINE
  ? parseInt(process.env.REACT_APP_FB_NEW_LINE)
  : NaN;
const FB_NEW_LINE: number = isNaN(FB_NEW_LINE_CODE) ? 8203 : FB_NEW_LINE_CODE;
const DUMMY_TEXTAREA = "textarea";
const SUCCESS_MESSAGE = process.env.REACT_APP_SUCCESS_MESSAGE
  ? process.env.REACT_APP_SUCCESS_MESSAGE
  : "Conversion is successful! Paste on a posting form in Facebook!";
const FAILED_MESSAGE = process.env.REACT_APP_FAILED_MESSAGE
  ? process.env.REACT_APP_FAILED_MESSAGE
  : "Conversion failed!";
export default class Home extends React.Component<IProps, IState> {
  private fileName: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  constructor(props: IState) {
    super(props);
    this.state = {
      contents: "",
      converted: false,
      selected: false,
    };
  }
  clear() {
    this.setState({
      contents: "",
      converted: false,
      selected: false,
    });
  }
  contentsChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.currentTarget.value;
    this.setState({
      contents: value,
    });
  }
  convert() {
    const value: string = this.state.contents;
    let contents: string = "";
    for (let i = 0; i < value.length; i++) {
      const c: string = value[i];
      if (c === NEW_LINE) {
        contents = contents + c + String.fromCharCode(FB_NEW_LINE);
      } else {
        contents = contents + c;
      }
    }
    this.setState({
      contents: contents,
    });
    this.toClipboard(contents);
    this.setState({
      converted: true,
    });
  }
  fileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.fileName.current && e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      // Sets file name to the file name field
      this.fileName.current.value = file.name;
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        if (e.target && e.target.error) {
          // Error
          console.log(e.target.error);
        } else if (e.target && e.target.result) {
          // Normal
          this.setState({
            contents: e.target.result as string,
            selected: true,
          });
        } else {
          // Error (no target)
        }
      };
    }
  }
  toClipboard(text: string): void {
    const textArea: HTMLTextAreaElement = document.createElement(
      DUMMY_TEXTAREA
    );
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? SUCCESS_MESSAGE : FAILED_MESSAGE;
      const converted = successful ? true : false;
      this.setState({
        converted: converted,
      });
      alert(msg);
    } catch (err) {
      this.setState({
        converted: false,
      });
      alert(FAILED_MESSAGE);
    }
    document.body.removeChild(textArea);
  }
  render() {
    return (
      <React.Fragment>
        <SelectFile
          name={this.fileName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.fileSelected(e)
          }
          selected={this.state.selected}
        />
        <PostContents
          clear={() => this.clear()}
          convert={() => this.convert()}
          converted={this.state.converted}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            this.contentsChanged(e)
          }
          value={this.state.contents}
        />
      </React.Fragment>
    );
  }
}
