import * as React from "react";
export default class Help extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Facebookの投稿ツール</h1>
        <p>
          Facebookの投稿に空改行を入れた場合にから改行を投稿に反映させるためのツールです。
        </p>
        <h2>使い方</h2>
        <p>
          投稿の現行のテキストファイルを指定して読み込むか、入力フォームに投稿する文章をカット＆ペーストしてください。
        </p>
        <p>
          その後、「変換」ボタンをクリックするとクリックボードに投稿する内容がコピーされます。
        </p>
        <p>Facebookの投稿フォームにクリップボードのデータをペーストします</p>
      </React.Fragment>
    );
  }
}
