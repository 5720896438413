import * as React from "react";
import "./styles/postContents.css";
interface IProps {
  value: string;
  convert:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  clear:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  onChange:
    | ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
    | undefined;
  converted: boolean;
}
interface IState {}
export default class PostContents extends React.Component<IProps, IState> {
  render() {
    if (!this.props.converted) {
      // Before conversion
      return (
        <React.Fragment>
          <div className="post_contents form-group">
            <label>投稿</label>
            <textarea
              id="content_area"
              className="form-control"
              value={this.props.value}
              onChange={this.props.onChange}
              placeholder="投稿を入力してください"
            ></textarea>
            <button className="btn btn-warning" onClick={this.props.convert}>
              変換
            </button>
          </div>
        </React.Fragment>
      );
    } else {
      // After conversion
      return (
        <React.Fragment>
          <div className="post_contents form-group">
            <label>Post</label>
            <textarea
              id="content_area"
              className="form-control"
              value={this.props.value}
              onChange={this.props.onChange}
              placeholder="Put your post here"
            ></textarea>
            <button className="btn btn-secondary" onClick={this.props.clear}>
              Clear
            </button>
          </div>
        </React.Fragment>
      );
    }
  }
}
